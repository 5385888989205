import logo from './_images/logo.png';
import bottom from './_images/bgbottom3.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img style={{position: "absolute", bottom: "0", left: "0", zIndex: "-1"}} src={bottom} alt="" width="100%" />
      <img src={logo} alt="Cundle.com" width="98%" style={{}} />
      
    </div>
  );
}

export default App;
